@import '../../components/common/css/extend.scss';

.address {
  margin-top: 30px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  background: #fff;
  padding: 54px 40px 50px;

  .icon {
    border: 1px solid #ebebeb;
    border-radius: 100%;
    background: #fff;
    font-size: 28px;
    width: 78px;
    height: 78px;
    position: absolute;
    top: -13px;
    left: 47px;
    @extend %transition;
  }

  &:hover {
    .icon {
      background: #f9004d;
      border-color: #f9004d;
      color: #fff;
    }
  }

  .inner {
    h4.title {
      font-size: 18px;
      font-weight: 700;
      color: #1f1f25;
    }

    p {
      font-size: 18px;
      margin-bottom: 0;
      color: rgba(29,29,36,.75);
    }
  }
}