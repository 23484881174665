.mb-60 {
  margin-bottom: 60px;
}
@for $i from 1 through 40 {
  .ptb--#{5 * $i} { padding: 5px *$i 0; }
  .plr--#{5 * $i} { padding: 0 5px *$i; }
  .pt--#{5 * $i} { padding-top: 5px *$i; }
  .pb--#{5 * $i} { padding-bottom: 5px *$i; }
  .pl--#{5 * $i} { padding-left: 5px *$i;}
  .pr--#{5 * $i} { padding-right: 5px *$i;}
  .mt--#{5 * $i} {margin-top: 5px *$i !important;}
  .mb--#{5 * $i} {margin-bottom: 5px *$i;}
  .mr--#{5 * $i} {margin-right: 5px *$i;}
  .ml--#{5 * $i} {margin-left: 5px *$i;}
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  font-weight: 700;
  margin: 0 0 15px;
}

.h2,
h2 {
  font-size: 54px;
}

p {
    font-size: 18px;
    line-height: 30px;
}

.small {
  font-size: 12px !important;
  line-height: 24px !important;
}

.medium {
  font-size: 14px !important;
  line-height: 26px !important;
}

.big {
  font-size: 16px !important;
  line-height: 28px !important;
}

.bigger {
  font-size: 18px !important;
  line-height: 30px !important;
}

/*===================
Custom Row
======================*/
.row--0 {
  margin-left: -0px;
  margin-right: -0px;

  &>[class*="col"] {
      padding-left: 0px;
      padding-right: 0px;
  }
}

.row--5 {
  margin-left: -5px;
  margin-right: -5px;

  &>[class*="col"] {
      padding-left: 5px;
      padding-right: 5px;
  }
}
