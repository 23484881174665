@import './variables.scss';

$charcoal: #444;
$dust: #c6c9d8;
$theme-color: #f9004d;
$watermelon: #db084d;
$white: #ffffff;
$grey: #e6e6e6;

.dark {
  color: #1f1f25;
}
.charcoal {
  color: $charcoal;
}
.dust {
  color: $dust;
}

.grey {
  color: $grey;
}

.light-bg {
  background: rgba(255, 255, 255, 0.3);
}
