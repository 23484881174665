@import './colors.scss';
@import './projects.scss';

*,
:after,
:before {
  box-sizing: border-box;
}

.max-width {
  width: 100% !important;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.delay1 {
  transition-delay: 0.6s;
}

.delay2 {
  transition-delay: 1s;
}

.delay3 {
  transition-delay: 1.8s;
}

.relative {
  position: relative;
}

.w-100 {
  width: 100% !important;
}

// header
#header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #222222cc;

  .navbar-brand {
    img.gc-logo {
      top: 10px;
      height: 70px;
      position: absolute;
    }
  }

  .navbar-nav {
    .nav-item {
      margin: 0 15px;

      .nav-link {
        padding: 20px 0;
        font-size: 16px;
        font-weight: 500;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover,
        &.active {
          color: $watermelon;
        }
      }
    }
  }
}

.headline {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 15px;
}

// Section
.section {
  padding: 120px 0;
  &h2 {
    margin-bottom: 15px;
  }

  .section-title {
    p {
      margin-top: 20px;
      font-size: 18px;
    }
    span {
      color: $watermelon;
    }
  }
}
.lines {
  width: 6px;
  position: relative;
  margin: auto;
}

.lines,
.lines:before,
.lines:after {
  border-top: 2px solid $watermelon;
}

.lines:before,
.lines:after {
  content: '';
  position: absolute;
  top: -2px;
  width: 40px;
  border-top: 2px solid $watermelon;
}

.lines:before {
  left: -44px;
}

.lines:after {
  right: -44px;
}

// border
.wood-border {
  border-image: url('../../../images/wood.png') 1 round;
}

// services
.service-link {
  text-decoration: none;
  color: $charcoal;
  &:hover {
    text-decoration: none;
    color: $charcoal;
  }
}

// footer
.footer {
  display: block;

  .footer-left,
  .footer-wrapper {
    position: relative;
  }

  .footer-left {
    background: -webkit-linear-gradient(305deg, #f81f01, #ee076e);
    background: linear-gradient(145deg, #f81f01, #ee076e);
    padding: 120px;
    z-index: 2;
    margin-right: -15px;
    border-top-right-radius: 6px;

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: url('../../../images/pattern-1.png');
      content: '';
      z-index: 1;
      opacity: 0.5;
      border-top-right-radius: 6px;
    }
    .inner {
      z-index: 5;
      position: relative;
      &::after {
        //content: "tryDO";
        // content: url('../../../images/big-logo.png');
        position: absolute;
        right: 0;
        top: 50%;
        z-index: -1;
        font-size: 240px;
        font-weight: 900;
        color: rgba(0, 0, 0, 0.1);
        transform: translateY(-50%);
        width: 295px;
        height: 301px;
      }
      > span {
        color: $white;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 4px;
        display: block;
        margin-bottom: 9px;
      }

      h2 {
        color: $white;
        font-size: 75px;
        font-weight: 900;
        font-family: 'Montserrat', sans-serif;
        line-height: 90px;
      }
      a {
        &.theme-btn {
          margin-top: 30px;
          border-color: $white;
          color: $white;

          &:hover {
            background: $white;
            border-color: $white;
            color: $theme-color;
          }
        }
      }
    }
  }

  .footer-right {
    background-image: url('../../../images/clusters.jpg');
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 150px 70px 150px 120px;

    .footer-link {
      h4 {
        color: $dust;
        font-weight: 500;
        margin-bottom: 30px;
        opacity: 0.75;
      }
      ul {
        &.ft-links {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            a {
              color: $dust !important;
              font-size: 16px;
              opacity: 0.75;
              text-decoration: none;
            }

            &:hover {
              color: black;

              a {
                color: $theme-color !important;
                text-decoration: none;
              }
            }
            & + li {
              margin-top: 13px;
            }
          }
        }
      }
    }
  }

  .copyright-text {
    position: absolute;
    bottom: -114px;
  }
}

// buttons
button,
a {
  &.theme-btn {
    color: $dust;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 2px solid #b1b4c1;
    padding: 15px 40px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    transition: 0.3s;

    &.btn-dark {
      border: 2px solid #1f1f25 !important;
      color: #1f1f25 !important;
      &:hover {
        background: #1f1f25 !important;
        color: $white !important;
      }
    }
    &.btn-theme-color {
      border: 2px solid $theme-color;
      background: $theme-color;
      color: $white;
    }
    &.border-opacity-half {
      border-color: rgba(31, 31, 37, 0.1);
    }
    &.btn-small {
      padding: 10px 20px;
    }
    &.btn-primary-color {
      &:hover {
        border: 2px solid $theme-color;
        background: $theme-color;
        color: $white;
      }
    }
    &.btn-solid {
      border: 2px solid $theme-color;
      background: $theme-color;
      color: $white;
    }
    &.btn-solid {
      background: $theme-color;
      color: $white;
    }
    &:hover {
      background: transparent;
      color: $theme-color;
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
      text-decoration: none;
    }
  }
}

a,
button {
  &.rn-btn {
    padding: 0 23px;
    height: 40px;
    display: inline-block;
    line-height: 34px;
    border: 2px solid $theme-color;
    border-radius: 4px;
    font-size: 14px;
    position: relative;
    z-index: 2;
    color: $theme-color;
    letter-spacing: 0.2px;
    @extend %transition;
    text-transform: uppercase;
    &:hover {
      border: 2px solid $theme-color;
      transform: translateY(-3px);
      color: #ffffff;
      background: $theme-color;
    }
    &.btn-white {
      border: 2px solid #ffffff;
      color: #ffffff;
      &:hover {
        border-color: $theme-color;
      }
    }
  }
}

/* Social icon Styles  */

ul {
  &.social-share {
    padding: 0;
    margin: 0;
    margin: -6px;
    display: inline-block;
    li {
      margin: 6px;
      a {
        width: 40px;
        display: inline-block;
        height: 40px;
        border: 2px solid rgba(198, 201, 216, 0.75);
        line-height: 35px;
        color: rgba(198, 201, 216, 0.75);
        border-radius: 100%;
        text-align: center;
        font-size: 14px;
      }
      &:hover {
        a {
          background: $theme-color;
          border-color: $theme-color;
          color: #ffffff;
          box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
          transform: translateY(-3px);
        }
      }
    }
    &.color-theme {
      li {
        a {
          border-color: $theme-color;
          color: $theme-color;
          &:hover {
            background: $theme-color;
            border-color: $theme-color;
            color: #ffffff;
          }
        }
      }
    }
    &.social-style--2 {
      li {
        a {
          border: 0 none;
          width: 30px;
          height: auto;
          text-align: left;
          line-height: inherit;
          font-size: 16px;
        }
        &:hover {
          a {
            background: transparent;
            box-shadow: none;
          }
        }
      }
      &.color-theme {
        li {
          a {
            color: $theme-color;
            &:hover {
              color: currentColor;
            }
          }
        }
      }
      &.color-black {
        li {
          a {
            color: #1d1d24;
            opacity: 0.8;
            &:hover {
              color: $theme-color;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.parallax {
  background-attachment: fixed;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.parallax-inner {
  background: #0000007a;
  height: 100%;
  color: white;
}

.thumbnail {
  .img {
    border-radius: 5px;
    box-shadow: 0 25px 65px #0000001a;

    &.no-border {
      border-radius: none;
      box-shadow: none;
    }
  }
}

.page-banner {
  min-height: 500px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  @media screen and (max-width: $medium) {
    min-height: 300px;
  }

  .title {
    background: #f81f01;
    background: -webkit-linear-gradient(305deg, #f81f01, #ee076e);
    background: linear-gradient(145deg, #f81f01, #ee076e);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: capitalize;
    font-size: 72px;
  }

  p {
    font-size: 22px;
    line-height: 27px;
    color: #c6c9d8;
  }

  .container {
    position: relative;
    z-index: 3;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.data-black-overlay {
  opacity: 0.6;
  background-color: #00010c;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
