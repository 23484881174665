@import '../common/css/variables.scss';
@import '../common/css/colors.scss';
@import '../common/css/extend.scss';

.icon {
  font-size: 50px;
  font-weight: 400;
  margin-bottom: 23px;
  display: inline-flex;
  color: $watermelon;
}

#service-section {
  .service {
    margin-top: 30px;
    // background: #f6f6f6;
    background-color: #e6e6e6;
    background-image: url('../../images/noise.png');
    padding: 60px 45px;
    z-index: 2;
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 440px;
    overflow: auto;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f61b10),
        to(#ef0963)
      );
      background-image: -webkit-linear-gradient(left, #f61b10, #ef0963);
      background-image: linear-gradient(90deg, #f61b10, #ef0963);
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      border-radius: 10px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover {
      box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
      background-image: linear-gradient(90deg, #f61b10, #ef0963);

      .icon {
        color: #fff !important;
      }

      .content {
        h3.title {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    @media only screen and (max-width: $extra-large) and (min-width: $large) {
      // padding: 30px 28px;
    }

    .content {
      h3.title {
        font-size: 19px;
        margin-bottom: 19px;
        font-weight: 500;
        text-transform: capitalize;
      }
      p {
        color: rgba(29, 29, 36, 0.75);
      }
    }
  }
}

#about-section {
  .thumbnail {
    img {
      border-radius: 5px;
    }
  }
  ul.tab-style--1 {
    display: flex;
    margin: 0 -20px;
    flex-wrap: wrap;
  }
  ul.tabs_header {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      margin: 0 20px;
      display: inline-block;
      padding-bottom: 4px;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      outline: none;

      &:before {
        position: absolute;
        content: '';
        width: 30px;
        background: rgba(255, 255, 255, 0.41);
        -webkit-transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        height: 2px;
        bottom: 0;
      }
    }
    li.selected {
      color: #f9004d;

      &:before {
        width: 100%;
        background: currentColor;
      }
    }
  }
  .single-tab-content {
    padding-top: 20px;

    p {
      font-weight: 300;
      color: #a8a8a8;
      font-size: 18px;
      line-height: 30px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        margin-bottom: 10px;
        color: #7e7e7e;
        font-size: 16px;
        line-height: inherit;
        margin-bottom: 15px;
        font-weight: 300;

        i {
          color: #f9004d;
          margin-right: 5px;
        }
      }
    }
  }
}

.about-wrapper .thumbnail {
  position: relative;
  z-index: 2;
}

// clients section
#clients-section {
  ul {
    &.brand-list {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      @media screen and (max-width: $medium) {
        border-left: 0 none;
        border-bottom: 0 none;
      }
      li {
        flex-basis: 50%;
        text-align: center;
        height: 214px;
        line-height: 214px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        @extend %transition;
        cursor: pointer;

        @media screen and (max-width: $medium) {
          border-top: 0 none;
          border-right: 0 none;
          height: auto;
          line-height: 10;
        }
        img {
          opacity: 0.7;
          transition: 0.3s;
          max-height: 110px;
          max-width: 150px;
          @extend %transition;
          @media screen and (max-width: $medium) {
            max-width: 100px;
            height: auto;
          }
        }
        &:hover {
          background-color: $grey;
          transform: scale(1.1);
          border-radius: 6px;
          img {
            opacity: 1;
            // filter: brightness(0) invert(1);
          }
        }
      }

      &.branstyle--2 {
        li {
          flex-basis: 33.33%;
        }
      }
    }
  }
}
