.header-section {
    height: 100vh;
    padding-top: 200px;
    background: #35373C;
    position: relative;

    .header-content {
        z-index: 10;
        position: relative;
        color: #fff;
        overflow: hidden;

        #header-content-one {

            h2.headline, p.tagline, div.actions {
                transition-timing-function: ease;
                transition-duration: 0.6s;
            }

            &.slide-bottom-up {

                h2.headline, p.tagline, div.actions {
                    -ms-transform: translateX(-100%);
                    -webkit-transform: translateX(-100%);
                    transform: translateX(-100%);
                }

                &.animate-visible {

                  h2.headline, p.tagline, div.actions {
                      -ms-transform: none;
                      -webkit-transform: none;
                      transform: none;
                  }
              }
            }
        }

        #header-content-two {

            h2.headline, p.tagline, div.actions {
                transition-timing-function: ease;
                transition-duration: 4s;
                opacity: 0;
            }

            &.animate-visible {

                h2.headline, p.tagline, div.actions {
                    opacity: 1;
                    -ms-transform: none;
                    -webkit-transform: none;
                    transform: none;
                }
            }
        }

        a {
            color: #fff;
            text-decoration: none;
        }

        h2 {
            font-weight: 500 !important;
            line-height: 1.2;
        }
        .tagline {
            font-size: 24px;
            margin-bottom: 30px;
        }

        .actions {
            margin-top: 100px;
            .btn {
                margin-right: 30px;
            }
        }
    }
}

.carousel-wrapper {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    background: #35373C;

    .main-carousel-mask {
        background-color: rgba(0,0,0,0.6) 35%;
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.6) 35%), to(rgba(0,0,0,0)));
        background-image: -moz-linear-gradient(left, rgba(0,0,0,0.6) 35%, rgba(0,0,0,0));
        background-image: -ms-linear-gradient(left, rgba(0,0,0,0.6) 35%, rgba(0,0,0,0));
        background-image: -o-linear-gradient(left, rgba(0,0,0,0.6) 35%, rgba(0,0,0,0));
        background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.6) 35%, rgba(0,0,0,0));
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
    }
}


// 'https://themes.3rdwavemedia.com/startup-kit/bs5/1.1/assets/images/hero/hero-1.jpg'
// 'https://themes.3rdwavemedia.com/startup-kit/bs5/1.1/assets/images/hero/hero-2.jpg'
// 'https://themes.3rdwavemedia.com/startup-kit/bs5/1.1/assets/images/hero/hero-3.jpg'
// 'http://api.diaryforlife.info/uploads/4_001c42bfd2.jpg'
// 'http://trydo.rainbowit.net/assets/images/bg/bg-image-24.jpg'
#carousel-container {

    .carousel-item {
        height: 100vh;
    }

    #slide1 {
        background: url(
          '../../images/consult.jpg'
        ) no-repeat 50% top;
        background-size: cover;
    }

    #slide2 {
        background: url(
            '../../images/hotel.jpg'
        ) no-repeat 50% top;
        background-size: cover;
    }

    #slide3 {
        background: url(
            '../../images/team.jpeg'
        ) no-repeat 50% top;
        background-size: cover;
    }
}

