@import '../common/css/variables.scss';
@import '../common/css/colors.scss';

#services-page,
#service-page {
  .service {
    border: 1px solid #e6e6e6;
    border-bottom: 3px solid transparent;
    border-bottom-color: $watermelon !important;
    border-radius: 6px;
    background-image: url('../../images/noise.png');
    background-color: transparent;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    min-height: 500px;

    &:hover {
      background-color: #e6e6e6;
      cursor: pointer;
    }

    .service-banner {
      height: 200px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .service-content-wrapper {
      padding: 30px;
      padding-bottom: 0;
      position: relative;

      .icon {
        font-size: 40px;
        position: absolute;
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        box-shadow: 0 5px 35px 0 rgba(69, 80, 107, 0.09);
        top: -35px;
        right: 20px;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 4px;
      }
    }
  }
}

#service-page {
  .thumb img {
    width: 100%;
    border-radius: 6px;
  }

  .service-content {
    .service-title {
      border-left: 10px solid;
      padding-left: 10px;
    }
  }
}
